import axios from '@/api/axios'; // Assicurati di importare axios dalla tua configurazione

const state = {
    pazienti: [],
};

const getters = {
    allPazienti: (state) => {
        return state.pazienti.map(paziente => ({
            ...paziente,
            fullName: `${paziente.nome} ${paziente.cognome}`
        }));
    }
};

const actions = {
    async fetchPazienti({ commit }) {
        try {
            const response = await axios.get('/pazienti'); // Assicurati che l'URL sia corretto
            commit('setPazienti', response.data);
        } catch (error) {
            console.error('Errore durante il recupero dei pazienti:', error);
        }
    },
};

const mutations = {
    setPazienti: (state, pazienti) => {
        state.pazienti = pazienti;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

import axios from '@/api/axios';

const actions = {
    async saveTimesheet({ commit }, { index, timesheet }) {
        console.log("index: ", index)
        try {
            console.log("STORE", JSON.stringify(timesheet));
            const response = await axios.post('/timesheets', timesheet);
            if (response.status === 200) {
                commit('REFRESH_TIMESHEET', index, response.data); // Usa i dati restituiti dal backend
            }
        } catch (error) {
            console.error('Errore durante il salvataggio del timesheet:', error);
        }
    },

    async deleteTimesheet({ commit }, id) {
        try {
            console.log("delete: ", id)
            await axios.delete(`/timesheets/${id}`);
            commit('REMOVE_TIMESHEET', id);
        } catch (error) {
            console.error('Errore durante l\'eliminazione del timesheet:', error);
        }
    },

    async getTimesheetsByDate({ commit }, { date, username }) {
        try {
            console.log("username: " + username)
            const response = await axios.get(`/timesheets/date/${date}/username/${username}`);
            commit('SET_TIMESHEETS', response.data); // Salva i dati nel state
        } catch (error) {
            console.error('Errore durante il recupero dei timesheets:', error);
        }
    },

    // Nuova azione per ottenere i terapisti
    async fetchTerapisti({ commit }, { pazienteId, data, oraInizio, oraFine }) {
        commit
        try {
            const response = await axios.get('/timesheets/terapisti', {
                params: {
                    pazienteId,
                    data,
                    oraInizio,
                    oraFine
                }
            });
            return response.data; // Restituisce i dati dei terapisti
        } catch (error) {
            console.error('Errore durante il recupero dei terapisti:', error);
            throw error; // Propaga l'errore per gestirlo nel componente
        }
    }
};

const mutations = {
    ADD_TIMESHEET(state, timesheet) {
        state.items.push(timesheet);
    },

    REFRESH_TIMESHEET(state, index, timesheet) {
        state.items[index] = timesheet;
    },

    REMOVE_TIMESHEET(state, id) {
        state.items = state.items.filter(item => item.id !== id);
    },

    SET_TIMESHEETS(state, timesheets) {
        state.items = timesheets;
    }
};

const state = {
    items: [] // Inizializzato come array vuoto
};

const getters = {
    allTimesheets: (state) => state.items
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};

import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/components/Login.vue';
import Dashboard from '@/views/Dashboard.vue'; // La tua home page o pagina protetta
import DailyTimesheet from '@/views/DailyTimesheet.vue'; // Importa il componente DailyTimesheet
import MonthlyReport from '@/views/MonthlyReport.vue'; // Importa il componente DailyTimesheet
import store from '@/store';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      meta: { requiresAuth: true }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: { layout: 'none' } //campo per il layout per non mostrare la sidebar
    },
    {
      path: '/dailytimesheet',
      name: 'DailyTimesheet',
      component: DailyTimesheet,
      meta: { requiresAuth: true }
    },
    {
      path: '/monthlyreport',
      name: 'MonthlyReport',
      component: MonthlyReport,
      meta: { requiresAuth: true }
    }
  ]
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated'];
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next('/login');
  } else {
    next();
  }
});

export default router;

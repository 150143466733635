<template>
  <v-app>
    <v-container fluid fill-height class="d-flex align-center justify-center">
      <v-card class="mx-auto" max-width="400" dark>
        <v-card-title class="text-h5">Login</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="login">
            <v-text-field
              v-model="username"
              label="Username"
              prepend-icon="mdi-account"
              required
            ></v-text-field>
            <v-text-field
              v-model="password"
              label="Password"
              type="password"
              prepend-icon="mdi-lock"
              required
            ></v-text-field>
            <v-btn type="submit" color="teal">Login</v-btn>
            <v-alert v-if="error" type="error" dismissible class="mt-3">{{ error }}</v-alert>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      username: '',
      password: '',
      error: ''
    };
  },
  methods: {
    ...mapActions('auth', ['loginUser']),
    
    async login() {
      try {
        await this.loginUser({ username: this.username, password: this.password });
        this.$router.push({ name: 'Dashboard' }); // Redirect to home page on success
      } catch (e) {
        this.error = 'Invalid username or password';
      }
    }
  }
};
</script>

<style scoped>
.v-card {
  padding: 16px;
}

.mt-3 {
  margin-top: 16px; /* Spazio tra il bottone e l'alert */
}
</style>

<template>
  <v-app id="inspire">
    <v-card elevation="2" shaped tile>
      <v-container>
        <v-row align="center" class="fixed-header">
          <!-- Colonna sinistra -->
          <v-col cols="auto" class="pa-0 d-flex justify-start">
            <v-btn icon @click="removeOneMonth">
              <v-icon>mdi-chevron-left-box</v-icon>
            </v-btn>
            <v-btn icon @click="addOneMonth">
              <v-icon>mdi-chevron-right-box</v-icon>
            </v-btn>
          </v-col>

          <!-- Colonna centrale (espandibile) -->
          <v-col class="pa-0">
            <v-menu 
              v-model="menuData" 
              :close-on-content-click="false"
              content-class="date-picker-menu"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Date"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  @input="handleMonthChange"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                type="month"
                no-title
                @input="handleMonthChange"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="auto" class="pa-0">
            <v-autocomplete
              label="Paziente"
              prepend-icon="mdi-account"
              @change="onPazienteChange(index, item.paziente)"
            ></v-autocomplete>
            <!--<v-btn icon @click="reset">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>-->
          </v-col>

          <!-- Colonna destra -->
          <v-col cols="auto" class="pa-0 d-flex justify-end">
            <v-btn icon @click="addItem" label="Add">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <!--<v-btn icon @click="reset">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>-->
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-app>
</template>

<script>
export default {
}
</script>

<style scoped>
.fixed-header {
  margin-bottom: 16px;
}

.demarcated-row {
  margin-top: 10px;
  border-top: 1px solid rgb(112, 112, 112);
  padding-top: 10px;
}

.v-date-picker {
  width: 290px;
}

.teal {
  color: teal;
}

.date-picker-menu {
  margin-top: 8px; /* Aggiungi un po' di spazio per separare il v-date-picker dal v-text-field */
  border-radius: 0; /* Rimuovi il bordo arrotondato se necessario */
  box-shadow: none; /* Rimuovi l'ombra per evitare conflitti visivi */
}
</style>

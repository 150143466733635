<template>
  <v-app id="inspire">
    <v-card elevation="2" shaped tile>
      <v-container>
        <v-row align="center" class="fixed-header">
          <!-- Colonna sinistra -->
          <v-col cols="auto" class="pa-0 d-flex justify-start">
            <v-btn icon @click="removeOneDay">
              <v-icon>mdi-chevron-left-box</v-icon>
            </v-btn>
            <v-btn icon @click="addOneDay">
              <v-icon>mdi-chevron-right-box</v-icon>
            </v-btn>
          </v-col>

          <!-- Colonna centrale (espandibile) -->
          <v-col class="pa-0">
            <v-menu 
              v-model="menuData" 
              :close-on-content-click="false"
              content-class="date-picker-menu"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Date"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  @input="handleDateChange"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                @input="handleDateChange"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <!-- Colonna destra -->
          <v-col cols="auto" class="pa-0 d-flex justify-end">
            <v-btn icon @click="addItem" label="Add">
              <v-icon>mdi-plus-box</v-icon>
            </v-btn>
            <!--<v-btn icon @click="reset">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>-->
          </v-col>
        </v-row>

        <v-row v-for="(item, index) in items" :key="index" class="demarcated-row"> 
          <!-- <v-text-field length="3" readonly v-model="item.id"></v-text-field> -->
          <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="item.paziente"
              :items="pazienti"
              item-value="id"
              item-text="fullName"
              label="Paziente"
              prepend-icon="mdi-account"
              @change="onPazienteChange(index, item.paziente)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-chip
              v-for="(terapista, tIndex) in item.terapisti"
              :key="tIndex"
              class="ma-2"
              color="teal" 
              outlined
              x-small
            >
              <v-avatar left>
                <v-icon>mdi-account-circle</v-icon>
              </v-avatar>
              {{ terapista.nome }} {{ terapista.cognome }}
            </v-chip>
          </v-col>
          <v-col cols="12" sm="2">
            <v-menu
              ref="menuStart"
              v-model="item.menuStart"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="item.oraInizio"
                  label="Ora Inizio"
                  prepend-icon="mdi-timer"
                  v-bind="attrs"
                  v-on="on"
                  :rules="[() => validateOraStart(item)]"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="item.oraInizio"
                full-width
                no-title
                @change="item.menuStart = false"
                format="24hr"
                step="15"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="2">
            <v-menu
              ref="menuEnd"
              v-model="item.menuEnd"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="item.oraFine"
                  label="Ora Fine"
                  prepend-icon="mdi-timer-off"
                  v-bind="attrs"
                  v-on="on"
                  :rules="[() => validateOraEnd(item)]"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="item.oraFine"
                full-width
                no-title
                @change="item.menuEnd = false"
                format="24hr"
                step="15"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="2">
            <v-btn icon @click="saveItem(index)">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn icon @click="removeItem(index)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      menuData: false,
      date: new Date().toISOString().substr(0, 10),
      items: [{ paziente: null, oraInizio: '', oraFine: '', terapisti: [], menuStart: false, menuEnd: false }],
    };
  },
  computed: {
    ...mapGetters('dailyTimesheet', ['allTimesheets']),
    ...mapGetters('paziente', ['allPazienti']),
    ...mapGetters('auth', ['getUser']),
    pazienti() {
      return this.allPazienti;
    },
    timesheets() {
      return this.allTimesheets;
    },
  },
  methods: {
    ...mapActions('dailyTimesheet', ['saveTimesheet', 'deleteTimesheet']),
    ...mapActions('paziente', ['fetchPazienti']),
    ...mapActions('auth', ['fetchUser']), // Assicurati che l'azione per recuperare l'utente sia presente

    addOneDay() {
      const currentDate = new Date(this.date);
      currentDate.setDate(currentDate.getDate() + 1);
      this.date = currentDate.toISOString().substr(0, 10);
      this.handleDateChange(this.date);
    },

    removeOneDay() {
      const currentDate = new Date(this.date);
      currentDate.setDate(currentDate.getDate() - 1);
      this.date = currentDate.toISOString().substr(0, 10);
      this.handleDateChange(this.date);
    },

    addItem() {
      const loggedInTerapista = this.$store.getters['auth/getUser'];
      
      this.items.push({
        id: null,
        paziente: null,
        oraInizio: '',
        oraFine: '',
        terapisti: loggedInTerapista ? [loggedInTerapista] : [], // Aggiungi il terapista loggato
      });
    },

    removeItem(index) {
      console.log("removeItem",this.items[index].id)
      if (this.items[index].id != null) {
        this.removeOneItem(index);
      }
      this.items.splice(index, 1); 
      
    },

    handleDateChange(date) {
      this.date = date;
      this.fetchTimesheets();
    },

    fetchTimesheets() {
      const loggedInTerapista = this.$store.getters['auth/getUser'];
      console.log("Terapista logged in: "+JSON.stringify(loggedInTerapista.username));
      this.$store.dispatch('dailyTimesheet/getTimesheetsByDate', { date: this.date, username: loggedInTerapista.username })
        .then(() => {
          this.items = this.timesheets;
        })
        .catch(error => {
          console.error('Errore durante il recupero dei timesheets:', error);
        });
    },

    async saveItem(index) {
      console.log("Saveitem: ",index)
        try {
          const loggedInTerapista = this.$store.getters['auth/getUser'];
          const itemToSave = this.items[index];
          console.log("ID:->" + JSON.stringify(itemToSave))
          // Verifica che il paziente sia presente
          if (!itemToSave.paziente) {
              throw new Error('Paziente non selezionato');
          }

          var paziente = null;
          if (!itemToSave.id) {
            paziente = itemToSave.paziente
            console.log("NO ID", paziente)
          } else {
            paziente = itemToSave.paziente.id
            console.log("SI ID", itemToSave.paziente.id, JSON.stringify(paziente))
          }

          // Richiama l'azione dello store per ottenere i terapisti aggiuntivi
          const terapistiAggiuntivi = await this.$store.dispatch('dailyTimesheet/fetchTerapisti', {
              pazienteId: paziente,
              data: this.date,
              oraInizio: itemToSave.oraInizio,
               oraFine: itemToSave.oraFine
          });

          // Crea l'oggetto da salvare
          const itemToSaveData = {
              id: itemToSave.id || null,
              paziente: paziente,
              terapisti: [...new Set([...itemToSave.terapisti.map(t => t.id), loggedInTerapista.id, ...terapistiAggiuntivi.map(t => t.id)])], // Terapisti esistenti + quelli ottenuti dall'API + il terapista loggato
              data: this.date,
              oraInizio: itemToSave.oraInizio,
              oraFine: itemToSave.oraFine
          };

          // Stampa per il debug
          console.log('Dati del timesheet da salvare:', itemToSaveData);

          // Salva il timesheet
          await this.$store.dispatch('dailyTimesheet/saveTimesheet', { index: index, timesheet: itemToSaveData });

          this.fetchTimesheets();
        } catch (error) {
            console.error('Errore durante il salvataggio del timesheet:', error);
            alert('Errore durante il salvataggio');
        }
    },

    async removeOneItem(index) {
      try {
        console.log("removeOneItem: "+this.items)
        await this.$store.dispatch('dailyTimesheet/deleteTimesheet', this.items[index].id);
      } catch (error) {
        console.error('Errore durante l\'eliminazione del timesheet:', error);
      }
    },

    validateOraStart(item) {
      if (!item.oraInizio) return 'Ora di inizio è obbligatoria';
      if (item.oraFine && item.oraInizio >= item.oraFine) return 'Ora di inizio deve essere prima dell\'ora di fine';
      return true;
    },

    validateOraEnd(item) {
      if (!item.oraFine) return 'Ora di fine è obbligatoria';
      if (item.oraInizio && item.oraFine <= item.oraInizio) return 'Ora di fine deve essere dopo l\'ora di inizio';
      return true;
    },

    reset() {
      this.date = new Date().toISOString().substr(0, 10);
      this.items = [{ paziente: null, oraInizio: '', oraFine: '', terapisti: [] }];
    },

    onPazienteChange(index, paziente) {
      console.log('Paziente selezionato:', paziente);
      // Gestisci l'oggetto paziente selezionato qui
      this.items[index].paziente = paziente;
    }
  },

  mounted() {
    this.fetchPazienti(); // Carica i pazienti al montaggio del componente
    this.fetchTimesheets();
  }
};
</script>

<style scoped>
.fixed-header {
  margin-bottom: 16px;
}

/*.demarcated-row {
  border: 1px solid #5a5959;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #e0e0e0;
}*/

.demarcated-row {
  margin-top: 10px;
  border-top: 1px solid rgb(112, 112, 112);
  padding-top: 10px;
}

.v-date-picker {
  width: 290px;
}

.teal {
  color: teal;
}

.date-picker-menu {
  margin-top: 8px; /* Aggiungi un po' di spazio per separare il v-date-picker dal v-text-field */
  border-radius: 0; /* Rimuovi il bordo arrotondato se necessario */
  box-shadow: none; /* Rimuovi l'ombra per evitare conflitti visivi */
}
</style>

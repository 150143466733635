var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-card',{attrs:{"elevation":"2","shaped":"","tile":""}},[_c('v-container',[_c('v-row',{staticClass:"fixed-header",attrs:{"align":"center"}},[_c('v-col',{staticClass:"pa-0 d-flex justify-start",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.removeOneDay}},[_c('v-icon',[_vm._v("mdi-chevron-left-box")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.addOneDay}},[_c('v-icon',[_vm._v("mdi-chevron-right-box")])],1)],1),_c('v-col',{staticClass:"pa-0"},[_c('v-menu',{attrs:{"close-on-content-click":false,"content-class":"date-picker-menu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date","prepend-icon":"mdi-calendar"},on:{"input":_vm.handleDateChange},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuData),callback:function ($$v) {_vm.menuData=$$v},expression:"menuData"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":_vm.handleDateChange},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{staticClass:"pa-0 d-flex justify-end",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":"","label":"Add"},on:{"click":_vm.addItem}},[_c('v-icon',[_vm._v("mdi-plus-box")])],1)],1)],1),_vm._l((_vm.items),function(item,index){return _c('v-row',{key:index,staticClass:"demarcated-row"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.pazienti,"item-value":"id","item-text":"fullName","label":"Paziente","prepend-icon":"mdi-account"},on:{"change":function($event){return _vm.onPazienteChange(index, item.paziente)}},model:{value:(item.paziente),callback:function ($$v) {_vm.$set(item, "paziente", $$v)},expression:"item.paziente"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},_vm._l((item.terapisti),function(terapista,tIndex){return _c('v-chip',{key:tIndex,staticClass:"ma-2",attrs:{"color":"teal","outlined":"","x-small":""}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-account-circle")])],1),_vm._v(" "+_vm._s(terapista.nome)+" "+_vm._s(terapista.cognome)+" ")],1)}),1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-menu',{ref:"menuStart",refInFor:true,attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Ora Inizio","prepend-icon":"mdi-timer","rules":[function () { return _vm.validateOraStart(item); }]},model:{value:(item.oraInizio),callback:function ($$v) {_vm.$set(item, "oraInizio", $$v)},expression:"item.oraInizio"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(item.menuStart),callback:function ($$v) {_vm.$set(item, "menuStart", $$v)},expression:"item.menuStart"}},[_c('v-time-picker',{attrs:{"full-width":"","no-title":"","format":"24hr","step":"15"},on:{"change":function($event){item.menuStart = false}},model:{value:(item.oraInizio),callback:function ($$v) {_vm.$set(item, "oraInizio", $$v)},expression:"item.oraInizio"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-menu',{ref:"menuEnd",refInFor:true,attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Ora Fine","prepend-icon":"mdi-timer-off","rules":[function () { return _vm.validateOraEnd(item); }]},model:{value:(item.oraFine),callback:function ($$v) {_vm.$set(item, "oraFine", $$v)},expression:"item.oraFine"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(item.menuEnd),callback:function ($$v) {_vm.$set(item, "menuEnd", $$v)},expression:"item.menuEnd"}},[_c('v-time-picker',{attrs:{"full-width":"","no-title":"","format":"24hr","step":"15"},on:{"change":function($event){item.menuEnd = false}},model:{value:(item.oraFine),callback:function ($$v) {_vm.$set(item, "oraFine", $$v)},expression:"item.oraFine"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.saveItem(index)}}},[_c('v-icon',[_vm._v("mdi-content-save")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
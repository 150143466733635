import axios from '@/api/axios';

const state = {
    user: null,
    token: null,
};

const getters = {
    isAuthenticated: state => !!state.token,
    getUser: state => state.user,
};

const actions = {
    async loginUser({ commit }, { username, password }) {
        try {
            const response = await axios.post('/auth/login', { username, password });
            const { token, user } = response.data;

            // Memorizza il token e l'utente nel localStorage
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));

            commit('SET_USER', user);
            commit('SET_TOKEN', token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } catch (error) {
            throw new Error('Invalid username or password');
        }
    },

    logoutUser({ commit }) {
        // Rimuove token e user sia dallo store che dal localStorage
        commit('SET_USER', null);
        commit('SET_TOKEN', null);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        delete axios.defaults.headers.common['Authorization'];
    },

    async fetchUser({ commit }) {
        try {
            const response = await axios.get('/auth/user'); // Endpoint per recuperare i dettagli dell'utente loggato
            const user = response.data;
            commit('SET_USER', user);
        } catch (error) {
            console.error('Errore durante il recupero dei dettagli dell\'utente:', error);
        }
    },

    // Ripristina l'autenticazione dal localStorage
    autoLogin({ commit }) {
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));
        if (token && user) {
            commit('SET_USER', user);
            commit('SET_TOKEN', token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
    }
};

const mutations = {
    SET_USER(state, user) {
        state.user = user;
    },
    SET_TOKEN(state, token) {
        state.token = token;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
